import ready from '../../assets/hexadguy/Ready.svg'

export default {
  id: 'hexadtypes',
  description: {
    en: 'Please indicate whether you agree or disagree with the following statements, especially in relation to your gaming preferences - and just answer **intuitively!**',
    de: 'Bitte gib an, ob du den folgenden Aussagen zustimmst oder sie ablehnst, insbesondere in Bezug auf deine Präferenzen beim Spielen - und antworte einfach **intuitiv!**'
  },
  image: ready,
  layout: 'grid',
  elements: [
    {
      id: 'HP1',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: 'It makes me happy if I am able to help others.',
        de: 'Es macht mich glücklich anderen zu helfen.'
      },
      subtitle: {}
    },
    {
      id: 'HR4',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: 'If the reward is sufficient, I will put in the effort.',
        de: 'Bei angemessener Belohnung strenge ich mich gerne entsprechend an.'
      },
      subtitle: {}
    },
    {
      id: 'HF3',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: { en: 'Being independent is important to me.', de: 'Unabhängigkeit ist mir wichtig.' },
      subtitle: {}
    },
    {
      id: 'HP4',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: 'The well-being of others is important to me.',
        de: 'Das Wohlergehen anderer ist mir wichtig.'
      },
      subtitle: {}
    },
    {
      id: 'HS4',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: { en: 'I enjoy group activities.', de: 'Gruppenaktivitäten machen mir Spaß.' },
      subtitle: {}
    },
    {
      id: 'HA4',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: 'I enjoy emerging victorious out of difficult circumstances.',
        de: 'Ich mag es, aus schwierigen Umständen siegreich hervorzugehen.'
      },
      subtitle: {}
    },
    {
      id: 'HA2',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: 'I like mastering difficult tasks.',
        de: 'Ich mag es, schwierige Aufgaben zu meistern.'
      },
      subtitle: {}
    },
    {
      id: 'HD4',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: { en: 'I dislike following rules.', de: 'Ich halte mich nicht gerne an Regeln.' },
      subtitle: {}
    },
    {
      id: 'HF1',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: 'It is important to me to follow my own path.',
        de: 'Es ist mir wichtig, meinen eigenen Weg zu gehen.'
      },
      subtitle: {}
    },
    {
      id: 'HS2',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: { en: 'I like being part of a team.', de: 'Ich bin gerne Teil eines Teams.' },
      subtitle: {}
    },
    {
      id: 'HR2',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: {
        en: 'Rewards are a great way to motivate me.',
        de: 'Belohnungen sind ein tolles Mittel, um mich zu motivieren.'
      },
      subtitle: {}
    },
    {
      id: 'HD3',
      type: { name: 'likert-7', options: {} },
      optional: false,
      title: { en: 'I see myself as a rebel.', de: 'Ich würde mich als rebellisch bezeichnen.' },
      subtitle: {}
    }
  ]
}
