import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Sticky from 'react-stickynode'
import { createPageIndices } from '../util/dataTools'
import '../css/progress-bar.css'
import { useDispatch, useSelector } from 'react-redux'
import { syncSession } from '../redux/slice'

export default ({ data }) => {
  const { pageId } = useParams()
  const progress = useSelector((state) => state.progress)
  const dispatch = useDispatch()
  const [circleDimenstions] = useState({ width: 36, stroke: 3 })
  const [mobileCircleDimenstions] = useState({ width: 34, stroke: 3 })
  const page_indices = createPageIndices(data)

  let radius = circleDimenstions.width / 2 - circleDimenstions.stroke / 2
  let circumference = radius * 2 * Math.PI

  let mobileRadius = mobileCircleDimenstions.width / 2 - mobileCircleDimenstions.stroke / 2
  let mobileCircumference = mobileRadius * 2 * Math.PI

  const calcOffset = (progress) => {
    if (!progress) {
      progress = 0
    }
    return circumference - (progress / 100) * circumference
  }

  const calcMobileOffset = (progress) => {
    if (!progress) {
      progress = 0
    }
    return mobileCircumference - (progress / 100) * mobileCircumference
  }

  if (Object.keys(progress).length < 2) {
    return null
  }

  return (
    <>
      <div className="progress-bar">
        {Object.keys(progress).map((page) => {
          return (
            <React.Fragment key={page + '_progress_fragment'}>
              <Link
                to={'/quiz/' + page_indices[page]}
                key={page + '_progress_link'}
                onClick={() => dispatch(syncSession())}
              >
                <svg
                  key={page + '_progress_node'}
                  viewBox="0 0 36 36"
                  className={`${pageId === page_indices[page] ? 'active' : ''} ${
                    progress[page].progress === '100.0' ? 'done' : ''
                  } ${parseFloat(progress[page].progress) > 0 ? 'in-progress' : ''}`}
                >
                  <circle
                    className="background-circle"
                    cx={circleDimenstions.width / 2}
                    cy={circleDimenstions.width / 2}
                    r={radius}
                  />
                  <circle
                    className="progress-circle"
                    cx={circleDimenstions.width / 2}
                    cy={circleDimenstions.width / 2}
                    r={radius}
                    strokeLinecap="round"
                    strokeDasharray={circumference + ' ' + circumference}
                    strokeDashoffset={calcOffset(progress[page].progress)}
                  />
                  {/* SVG from https://iconmonstr.com/ */}
                  <path
                    className="progress-check"
                    transform="translate(11, 11) scale(0.6)"
                    width="10px"
                    height="10px"
                    d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z"
                  />
                  <circle
                    className="active-circle"
                    transform="translate(10, 10)"
                    cx="8"
                    cy="8"
                    r="8"
                  />
                  <circle
                    className="active-done-circle"
                    transform="translate(11, 11)"
                    cx="7"
                    cy="7"
                    r="7"
                    fill="transparent"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
              </Link>
              {page !== 'demographics' && (
                <div className="bullet-line" key={page + '_progress_line'}></div>
              )}
            </React.Fragment>
          )
        })}
      </div>
      <Sticky
        top={0}
        bottomBoundary=".footer"
        className="progress-bar-mobile-wrapper"
        innerClass="progress-bar-mobile"
      >
        <div className="progress-bar-mobile-inner">
          {Object.keys(progress).map((page) => {
            // return (
            //     <React.Fragment key={page + "_progress_fragment_mobile"}>
            //         <Link to={"/quiz/" + page_indices[page]} key={page + "_progress_link_mobile"} >
            //             <div className={`progress-circle-mobile ${pageId === page_indices[page] ? 'active' : ''} ${props.progress[page].progress === "100.0" ? 'done' : ''}`}>
            //                 <i className="im im-check-mark"></i>
            //                 <div className="active-indicator-mobile"></div>
            //             </div>
            //         </Link>
            //         {page !== "demographics" &&
            //             <div className="bullet-line-mobile" key={page + "_progress_line_mobile"}></div>
            //         }
            //     </React.Fragment>
            // )
            return (
              <React.Fragment key={page + '_progress_fragment_mobile'}>
                <Link
                  to={'/quiz/' + page_indices[page]}
                  key={page + '_progress_link'}
                  onClick={() => dispatch(syncSession())}
                >
                  <svg
                    key={page + '_progress_node'}
                    viewBox="0 0 35 35"
                    className={`${pageId === page_indices[page] ? 'active' : ''} ${
                      progress[page].progress === '100.0' ? 'done' : ''
                    } ${parseFloat(progress[page].progress) > 0 ? 'in-progress' : ''}`}
                  >
                    <circle
                      className="background-circle"
                      cx={circleDimenstions.width / 2}
                      cy={circleDimenstions.width / 2}
                      r={mobileRadius}
                    />
                    <circle
                      className="progress-circle"
                      cx={circleDimenstions.width / 2}
                      cy={circleDimenstions.width / 2}
                      r={mobileRadius}
                      strokeLinecap="round"
                      strokeDasharray={mobileCircumference + ' ' + mobileCircumference}
                      strokeDashoffset={calcMobileOffset(progress[page].progress)}
                    />
                    {/* SVG from https://iconmonstr.com/ */}
                    <path
                      className="progress-check"
                      transform="translate(12, 12) scale(0.5)"
                      width="10px"
                      height="10px"
                      d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z"
                    />
                    <circle
                      className="active-circle"
                      transform="translate(10, 10)"
                      cx="8"
                      cy="8"
                      r="8"
                    />
                    <circle
                      className="active-done-circle"
                      transform="translate(11, 11)"
                      cx="7"
                      cy="7"
                      r="7"
                      fill="transparent"
                      stroke="#fff"
                      strokeWidth="2"
                    />
                  </svg>
                </Link>
                {page !== 'demographics' && (
                  <div className="bullet-line" key={page + '_progress_line'}></div>
                )}
              </React.Fragment>
            )
          })}
        </div>
      </Sticky>
    </>
  )
}
